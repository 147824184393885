import React, { useCallback, useEffect, useState } from "react";
import Highlighted from "./Highlited";
import { Autocomplete, Backdrop, Box, Button, CircularProgress, Divider, Grid, Modal, Stack, TextField, InputLabel } from "@mui/material";
import { NormalizationSql } from "./models";


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "75%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function App() {
    const [sql, setSql] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const [examples, setExamples] = useState<NormalizationSql[]>([]);
    const [selectedExample, setSelectedExample] = useState<NormalizationSql | null>(null);

    const [question, setQuestion] = useState<string>("");
    const [generatedSql, setGeneratedSql] = useState<string>("");

    useEffect(() => {
        const url = new URL(`${process.env.REACT_APP_SQL_NORMALIZER_BACKEND}/api/Normalization/examples`);

        setIsLoading(true);

        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.isError) {
                    alert(data.firstError.description);
                    return;
                }
                setExamples(data.value);
                if (data.value && data.value.length) {
                    setSelectedExample(data.value[0]);
                    setSql(data.value[0].sql);
                }
            })
            .catch(error => alert(error))
            .finally(() => setIsLoading(false));
    }, []);

    const onQuestionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setQuestion(event.target.value);
    }, [setQuestion]);


    const onGenerateSql = useCallback(() => {
        setIsLoading(true);
        const url = new URL(`${process.env.REACT_APP_SQL_NORMALIZER_BACKEND}/api/SqlGenerator`);
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Schema: sql,
                SchemaNormalized: null,
                Question: question,
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.isError) {
                    alert(data.firstError.description);
                    return;
                }
                setGeneratedSql(data.value);
            })
            .catch(error => alert(error))
            .finally(() => setIsLoading(false));
    }, [sql, setIsLoading, setGeneratedSql, generatedSql, question]);


    return <Box height="97vh" display="flex" flexDirection="column">
        <Stack direction="row" spacing={2}>
            <Autocomplete
                options={examples}
                getOptionLabel={(option: NormalizationSql) => option.name}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Examples" />}
                value={selectedExample}
                onChange={(event: any, newValue: NormalizationSql | null) => {
                    if (newValue !== null) {
                        setSelectedExample(newValue);
                        setSql(newValue.sql);
                    }
                }}
            />
        </Stack>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} paddingLeft={3}>
            <Grid xs={7} paddingRight={2}>
                <Highlighted code={sql || ""} onDoubleClick={() => setIsOpen(true)} />
            </Grid>

            <Grid xs={5}>
                <TextField variant="filled" style={{ width: "100%" }} label="Question" value={question} onChange={onQuestionChange} />
                <Divider orientation="vertical" flexItem> </Divider>    
                <InputLabel>Generated SQL</InputLabel>
                <Highlighted code={generatedSql || ""} onDoubleClick={() => true} />
                <Button variant="contained" fullWidth onClick={onGenerateSql}>Generate SQL</Button>
            </Grid>
        </Grid>
        <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={isLoading}
            onClick={() => setIsLoading(!isLoading)}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

        <Modal
            open={isOpen}
            onClose={() => { setIsOpen(false); }}
            aria-labelledby="modal-sql"
            aria-describedby="modal-sql-description"
        >
            <Box sx={modalStyle}>
                <TextField
                    multiline
                    maxRows={25}
                    value={sql}
                    style={{ width: "100%" }}
                    onChange={useCallback((v: React.ChangeEvent<HTMLInputElement>) => {
                        setSql(v.target.value);
                    }, [sql, setSql])} />
            </Box>
        </Modal>
    </Box>;
}

export default App;