import { Box } from "@mui/material";
import { Highlight, themes } from "prism-react-renderer"
import React from "react";
import { useCallback } from "react";

interface HighlightedProps {
    code: string,
    onDoubleClick: () => void,
}

export default function Highlighted({ code, onDoubleClick }: HighlightedProps) {
    const onClickHandler = useCallback((event: any) => {
        if (event.detail == 2) {
            onDoubleClick();
        }
    }, [onDoubleClick]);


    return <Box onClick={onClickHandler} maxHeight={"90vh"} overflow={"scroll"} marginTop={1.5}>
        <Highlight
            theme={themes.vsDark}
            code={code}
            language="sql"
        >
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <pre style={style}>
                    {tokens.map((line, i) => (
                        <div key={i} {...getLineProps({ line })}>
                            {line.map((token, key) => {
                                return <span key={key} {...getTokenProps({ token })} />
                            })}
                        </div>
                    ))}
                </pre>
            )}
        </Highlight>
    </Box >
}
